import * as Apollo from "@apollo/client"
import { gql } from "@apollo/client"
const defaultOptions = {} as const

export type PossibleTypesResultData = {
  possibleTypes: {
    ContentPageContentDynamicZone: [
      "ComponentSectionsBanner",
      "ComponentSectionsCareersGroup",
      "ComponentSectionsForm",
      "ComponentSectionsGrid",
      "ComponentSectionsHeading",
      "ComponentSectionsPartnersSection",
      "ComponentSectionsSlider",
      "ComponentSectionsTextWithImage",
      "Error",
    ]
    GenericMorph: [
      "CaseRequest",
      "ComponentSectionsBanner",
      "ComponentSectionsCareersGroup",
      "ComponentSectionsCookieBanner",
      "ComponentSectionsForm",
      "ComponentSectionsGrid",
      "ComponentSectionsHeading",
      "ComponentSectionsNoConnection",
      "ComponentSectionsPartnersSection",
      "ComponentSectionsSlider",
      "ComponentSectionsTextWithImage",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiContacts",
      "ComponentUiContactsGroup",
      "ComponentUiFormInput",
      "ComponentUiLink",
      "ComponentUiLinksGroup",
      "ComponentUiSlide",
      "ComponentUiText",
      "ComponentUiVacancie",
      "ComponentUiVacancies",
      "Contact",
      "ContactAnswer",
      "ContentPage",
      "CookiePolicy",
      "EmailTemplate",
      "I18NLocale",
      "Layout",
      "NoConnection",
      "NotFound",
      "Partner",
      "PrivacyPolicy",
      "ReactIconsIconlibrary",
      "TermAndCondition",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VacancyResponse",
    ]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    ContentPageContentDynamicZone: [
      "ComponentSectionsBanner",
      "ComponentSectionsCareersGroup",
      "ComponentSectionsForm",
      "ComponentSectionsGrid",
      "ComponentSectionsHeading",
      "ComponentSectionsPartnersSection",
      "ComponentSectionsSlider",
      "ComponentSectionsTextWithImage",
      "Error",
    ],
    GenericMorph: [
      "CaseRequest",
      "ComponentSectionsBanner",
      "ComponentSectionsCareersGroup",
      "ComponentSectionsCookieBanner",
      "ComponentSectionsForm",
      "ComponentSectionsGrid",
      "ComponentSectionsHeading",
      "ComponentSectionsNoConnection",
      "ComponentSectionsPartnersSection",
      "ComponentSectionsSlider",
      "ComponentSectionsTextWithImage",
      "ComponentUiButton",
      "ComponentUiCard",
      "ComponentUiContacts",
      "ComponentUiContactsGroup",
      "ComponentUiFormInput",
      "ComponentUiLink",
      "ComponentUiLinksGroup",
      "ComponentUiSlide",
      "ComponentUiText",
      "ComponentUiVacancie",
      "ComponentUiVacancies",
      "Contact",
      "ContactAnswer",
      "ContentPage",
      "CookiePolicy",
      "EmailTemplate",
      "I18NLocale",
      "Layout",
      "NoConnection",
      "NotFound",
      "Partner",
      "PrivacyPolicy",
      "ReactIconsIconlibrary",
      "TermAndCondition",
      "UploadFile",
      "UploadFolder",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VacancyResponse",
    ],
  },
}
export default result

export const FileFragmentDoc = gql`
  fragment File on UploadFileEntity {
    id
    attributes {
      previewUrl
      alternativeText
      url
    }
  }
`
export const ButtonFragmentDoc = gql`
  fragment Button on ComponentUiButton {
    id
    url
    icon
    title
    image {
      data {
        ...File
      }
    }
  }
`
export const HeadingFragmentDoc = gql`
  fragment Heading on ComponentSectionsHeading {
    id
    title
    description
    lottieMobile
    lottieWeb
    lottieTablet
    animation {
      data {
        ...File
      }
    }
    animationDescription
    fullImage {
      data {
        ...File
      }
    }
    button {
      ...Button
    }
  }
`
export const ContactFragmentDoc = gql`
  fragment Contact on ContactEntity {
    id
    __typename
    attributes {
      successText
      successTitle
      submitButtonTitle
      title
      fullNameFieldTitle
      emailFieldTitle
      aboutFieldTitle
      companyFieldTitle
      heading {
        ...Heading
      }
      contacts {
        title
        url
        subtitle
        id
      }
      descriptions
    }
  }
`
export const ContactsGroupFragmentDoc = gql`
  fragment ContactsGroup on ComponentUiContactsGroup {
    __typename
    id
    title
    item {
      ...Button
    }
  }
`
export const LinkFragmentDoc = gql`
  fragment Link on ComponentUiLink {
    id
    title
    url
    target
    icon
  }
`
export const LinksGroupFragmentDoc = gql`
  fragment LinksGroup on ComponentUiLinksGroup {
    id
    url
    target
    title
    __typename
    link {
      ...Link
    }
  }
`
export const CardFragmentDoc = gql`
  fragment Card on ComponentUiCard {
    id
    __typename
    title
    subtitle
    description
    count
    value
    media {
      data {
        ...File
      }
    }
    button {
      ...Button
    }
    icon
  }
`
export const GridFragmentDoc = gql`
  fragment Grid on ComponentSectionsGrid {
    __typename
    id
    title
    subtitle
    type
    items {
      ...Card
    }
  }
`
export const BannerFragmentDoc = gql`
  fragment Banner on ComponentSectionsBanner {
    __typename
    id
    title
    animation {
      data {
        ...File
      }
    }
    button {
      ...Button
    }
  }
`
export const SlideFragmentDoc = gql`
  fragment Slide on ComponentUiSlide {
    title
    id
    description
    __typename
    image {
      data {
        ...File
      }
    }
  }
`
export const SliderFragmentDoc = gql`
  fragment Slider on ComponentSectionsSlider {
    __typename
    id
    title
    slide {
      ...Slide
    }
  }
`
export const TextWithImageFragmentDoc = gql`
  fragment TextWithImage on ComponentSectionsTextWithImage {
    id
    subtitle
    title
    description
    location
    media {
      data {
        ...File
      }
    }
    blackImage {
      data {
        ...File
      }
    }
    whiteImage {
      data {
        ...File
      }
    }
    __typename
  }
`
export const VacancyFragmentDoc = gql`
  fragment Vacancy on ComponentUiVacancie {
    id
    button {
      ...Button
    }
    name
    description
  }
`
export const CareerFragmentDoc = gql`
  fragment Career on ComponentSectionsCareersGroup {
    vacancy {
      id
      title
      item {
        ...Vacancy
      }
    }
    __typename
  }
`
export const FormInputFragmentDoc = gql`
  fragment FormInput on ComponentUiFormInput {
    id
    name
    label
  }
`
export const FormFragmentDoc = gql`
  fragment Form on ComponentSectionsForm {
    title
    text
    button {
      ...Button
    }
    successText
    successTitle
    companyInput {
      ...FormInput
    }
    emailInput {
      ...FormInput
    }
    fileInput {
      ...FormInput
    }
    firstNameInput {
      ...FormInput
    }
    lastNameInput {
      ...FormInput
    }
    noteInput {
      ...FormInput
    }
    companyInput {
      ...FormInput
    }
  }
`
export const PartnerFragmentDoc = gql`
  fragment Partner on Partner {
    logo {
      data {
        ...File
      }
    }
    link
  }
`
export const PartnerSectionFragmentDoc = gql`
  fragment PartnerSection on ComponentSectionsPartnersSection {
    id
    __typename
    title
    partners(pagination: { limit: -1 }) {
      data {
        id
        attributes {
          ...Partner
        }
      }
    }
  }
`
export const PageFragmentDoc = gql`
  fragment Page on ContentPage {
    __typename
    slug
    content {
      ...Grid
      ...Banner
      ...Heading
      ...Slider
      ...TextWithImage
      ...Career
      ...Form
      ...PartnerSection
    }
  }
`
export const CareersFormDocument = gql`
  mutation careersForm($input: SendApplyVacancyInput!) {
    sendApplyVacancy(input: $input) {
      isSent
    }
  }
`
export type CareersFormMutationFn = Apollo.MutationFunction<CareersFormMutation, CareersFormMutationVariables>
export function useCareersFormMutation(
  baseOptions?: Apollo.MutationHookOptions<CareersFormMutation, CareersFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<CareersFormMutation, CareersFormMutationVariables>(CareersFormDocument, options)
}
export type CareersFormMutationHookResult = ReturnType<typeof useCareersFormMutation>
export type CareersFormMutationResult = Apollo.MutationResult<CareersFormMutation>
export const ContactFormDocument = gql`
  mutation contactForm($input: SendContactUsInput!) {
    sendContactUs(input: $input) {
      isSent
    }
  }
`
export type ContactFormMutationFn = Apollo.MutationFunction<ContactFormMutation, ContactFormMutationVariables>
export function useContactFormMutation(
  baseOptions?: Apollo.MutationHookOptions<ContactFormMutation, ContactFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<ContactFormMutation, ContactFormMutationVariables>(ContactFormDocument, options)
}
export type ContactFormMutationHookResult = ReturnType<typeof useContactFormMutation>
export type ContactFormMutationResult = Apollo.MutationResult<ContactFormMutation>
export const SendFileDocument = gql`
  mutation sendFile($data: UploadFileInput!) {
    createUploadFile(data: $data) {
      data {
        attributes {
          url
        }
      }
    }
  }
`
export type SendFileMutationFn = Apollo.MutationFunction<SendFileMutation, SendFileMutationVariables>
export function useSendFileMutation(
  baseOptions?: Apollo.MutationHookOptions<SendFileMutation, SendFileMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SendFileMutation, SendFileMutationVariables>(SendFileDocument, options)
}
export type SendFileMutationHookResult = ReturnType<typeof useSendFileMutation>
export type SendFileMutationResult = Apollo.MutationResult<SendFileMutation>
export const SolutionFormDocument = gql`
  mutation solutionForm($input: SendCaseRequestInput!) {
    sendCaseRequest(input: $input) {
      isSent
    }
  }
`
export type SolutionFormMutationFn = Apollo.MutationFunction<SolutionFormMutation, SolutionFormMutationVariables>
export function useSolutionFormMutation(
  baseOptions?: Apollo.MutationHookOptions<SolutionFormMutation, SolutionFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<SolutionFormMutation, SolutionFormMutationVariables>(SolutionFormDocument, options)
}
export type SolutionFormMutationHookResult = ReturnType<typeof useSolutionFormMutation>
export type SolutionFormMutationResult = Apollo.MutationResult<SolutionFormMutation>
export const ConnectionDocument = gql`
  query connection {
    noConnection {
      data {
        attributes {
          text
          title
          button {
            ...Button
          }
          image {
            data {
              attributes {
                url
                alternativeText
              }
            }
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
  ${FileFragmentDoc}
`
export function useConnectionQuery(baseOptions?: Apollo.QueryHookOptions<ConnectionQuery, ConnectionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, options)
}
export function useConnectionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ConnectionQuery, ConnectionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ConnectionQuery, ConnectionQueryVariables>(ConnectionDocument, options)
}
export type ConnectionQueryHookResult = ReturnType<typeof useConnectionQuery>
export type ConnectionLazyQueryHookResult = ReturnType<typeof useConnectionLazyQuery>
export type ConnectionQueryResult = Apollo.QueryResult<ConnectionQuery, ConnectionQueryVariables>
export const ContactDocument = gql`
  query contact {
    contact {
      data {
        ...Contact
      }
    }
  }
  ${ContactFragmentDoc}
  ${HeadingFragmentDoc}
  ${FileFragmentDoc}
  ${ButtonFragmentDoc}
`
export function useContactQuery(baseOptions?: Apollo.QueryHookOptions<ContactQuery, ContactQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options)
}
export function useContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContactQuery, ContactQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<ContactQuery, ContactQueryVariables>(ContactDocument, options)
}
export type ContactQueryHookResult = ReturnType<typeof useContactQuery>
export type ContactLazyQueryHookResult = ReturnType<typeof useContactLazyQuery>
export type ContactQueryResult = Apollo.QueryResult<ContactQuery, ContactQueryVariables>
export const CookieDocument = gql`
  query cookie {
    cookiePolicy {
      data {
        attributes {
          address
          title
          date
          text
        }
      }
    }
  }
`
export function useCookieQuery(baseOptions?: Apollo.QueryHookOptions<CookieQuery, CookieQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CookieQuery, CookieQueryVariables>(CookieDocument, options)
}
export function useCookieLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CookieQuery, CookieQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CookieQuery, CookieQueryVariables>(CookieDocument, options)
}
export type CookieQueryHookResult = ReturnType<typeof useCookieQuery>
export type CookieLazyQueryHookResult = ReturnType<typeof useCookieLazyQuery>
export type CookieQueryResult = Apollo.QueryResult<CookieQuery, CookieQueryVariables>
export const LayoutDocument = gql`
  query layout($locale: I18NLocaleCode) {
    layout(locale: $locale) {
      data {
        attributes {
          headerLogo {
            data {
              ...File
            }
          }
          footerLogo {
            data {
              ...File
            }
          }
          headerMenu {
            ...LinksGroup
          }
          footerNavigation {
            ...LinksGroup
          }
          footerContact {
            ...ContactsGroup
          }
          headerButton {
            ...Link
          }
        }
      }
    }
  }
  ${FileFragmentDoc}
  ${LinksGroupFragmentDoc}
  ${LinkFragmentDoc}
  ${ContactsGroupFragmentDoc}
  ${ButtonFragmentDoc}
`
export function useLayoutQuery(baseOptions?: Apollo.QueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options)
}
export function useLayoutLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LayoutQuery, LayoutQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<LayoutQuery, LayoutQueryVariables>(LayoutDocument, options)
}
export type LayoutQueryHookResult = ReturnType<typeof useLayoutQuery>
export type LayoutLazyQueryHookResult = ReturnType<typeof useLayoutLazyQuery>
export type LayoutQueryResult = Apollo.QueryResult<LayoutQuery, LayoutQueryVariables>
export const MeDocument = gql`
  query me {
    me {
      id
    }
  }
`
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options)
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>
export const NotFoundDocument = gql`
  query notFound {
    notFound {
      data {
        attributes {
          title
          text
          subtitle
          button {
            ...Button
          }
          astronaut {
            data {
              ...File
            }
          }
          moon {
            data {
              ...File
            }
          }
        }
      }
    }
  }
  ${ButtonFragmentDoc}
  ${FileFragmentDoc}
`
export function useNotFoundQuery(baseOptions?: Apollo.QueryHookOptions<NotFoundQuery, NotFoundQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotFoundQuery, NotFoundQueryVariables>(NotFoundDocument, options)
}
export function useNotFoundLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotFoundQuery, NotFoundQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotFoundQuery, NotFoundQueryVariables>(NotFoundDocument, options)
}
export type NotFoundQueryHookResult = ReturnType<typeof useNotFoundQuery>
export type NotFoundLazyQueryHookResult = ReturnType<typeof useNotFoundLazyQuery>
export type NotFoundQueryResult = Apollo.QueryResult<NotFoundQuery, NotFoundQueryVariables>
export const PageDocument = gql`
  query page($key: StringFilterInput!, $locale: I18NLocaleCode) {
    contentPages(filters: { slug: $key }, locale: $locale) {
      data {
        attributes {
          locale
          ...Page
        }
      }
    }
  }
  ${PageFragmentDoc}
  ${GridFragmentDoc}
  ${CardFragmentDoc}
  ${FileFragmentDoc}
  ${ButtonFragmentDoc}
  ${BannerFragmentDoc}
  ${HeadingFragmentDoc}
  ${SliderFragmentDoc}
  ${SlideFragmentDoc}
  ${TextWithImageFragmentDoc}
  ${CareerFragmentDoc}
  ${VacancyFragmentDoc}
  ${FormFragmentDoc}
  ${FormInputFragmentDoc}
  ${PartnerSectionFragmentDoc}
  ${PartnerFragmentDoc}
`
export function usePageQuery(baseOptions: Apollo.QueryHookOptions<PageQuery, PageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PageQuery, PageQueryVariables>(PageDocument, options)
}
export function usePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PageQuery, PageQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PageQuery, PageQueryVariables>(PageDocument, options)
}
export type PageQueryHookResult = ReturnType<typeof usePageQuery>
export type PageLazyQueryHookResult = ReturnType<typeof usePageLazyQuery>
export type PageQueryResult = Apollo.QueryResult<PageQuery, PageQueryVariables>
export const PrivacyDocument = gql`
  query privacy {
    privacyPolicy {
      data {
        attributes {
          address
          text
          date
          title
        }
      }
    }
  }
`
export function usePrivacyQuery(baseOptions?: Apollo.QueryHookOptions<PrivacyQuery, PrivacyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<PrivacyQuery, PrivacyQueryVariables>(PrivacyDocument, options)
}
export function usePrivacyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PrivacyQuery, PrivacyQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<PrivacyQuery, PrivacyQueryVariables>(PrivacyDocument, options)
}
export type PrivacyQueryHookResult = ReturnType<typeof usePrivacyQuery>
export type PrivacyLazyQueryHookResult = ReturnType<typeof usePrivacyLazyQuery>
export type PrivacyQueryResult = Apollo.QueryResult<PrivacyQuery, PrivacyQueryVariables>
export const TermsDocument = gql`
  query terms {
    termAndCondition {
      data {
        attributes {
          address
          date
          text
          title
        }
      }
    }
  }
`
export function useTermsQuery(baseOptions?: Apollo.QueryHookOptions<TermsQuery, TermsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<TermsQuery, TermsQueryVariables>(TermsDocument, options)
}
export function useTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TermsQuery, TermsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<TermsQuery, TermsQueryVariables>(TermsDocument, options)
}
export type TermsQueryHookResult = ReturnType<typeof useTermsQuery>
export type TermsLazyQueryHookResult = ReturnType<typeof useTermsLazyQuery>
export type TermsQueryResult = Apollo.QueryResult<TermsQuery, TermsQueryVariables>
