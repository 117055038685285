import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { ConfigProvider } from "antd"
import { createContext, Dispatch, FC, memo, PropsWithChildren, SetStateAction, useContext, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { useToggle } from "react-use"
import introspection from "../../graphql"
import routes from "../../pages"
import theme from "../../themes"

import "./index.less"

type AppProps = {
  burger: { opened: boolean; toggle: VoidFunction }
  lang: { language: string | null; setLanguage: (value: string) => void }
}

type ModalProps = {
  open: boolean
  closeModal: () => void
  openModal: () => void
  setContext: Dispatch<SetStateAction<null | Partial<ComponentSectionsForm>>>
  context: Partial<ComponentSectionsForm> | null
  itemName: Maybe<string>
  setName: Dispatch<SetStateAction<string>>
}

const defaultValue: AppProps = {
  burger: {
    opened: false,
    toggle: () => {},
  },
  lang: {
    language: "en",
    setLanguage: () => {},
  },
}

const initialValues: ModalProps = {
  closeModal: () => {},
  openModal: () => {},
  open: false,
  context: null,
  itemName: "",
  setName: () => {},
  setContext: () => {},
}

const Context = createContext<AppProps>(defaultValue)
const ModalContext = createContext<ModalProps>(initialValues)

type ContextProviderProps = PropsWithChildren<{}>
type ContextProviderModalProps = PropsWithChildren<{}>

const ContextProvider: FC<ContextProviderProps> = ({ children }) => {
  const [opened, toggle] = useToggle(false)
  localStorage.setItem("strapi-admin-language", "en")

  const lang = localStorage.getItem("strapi-admin-language")
  const [language, setLanguage] = useState(lang)

  return (
    <Context.Provider
      value={{ burger: { opened, toggle }, lang: { language, setLanguage: value => setLanguage(value) } }}
    >
      {children}
    </Context.Provider>
  )
}
const ModalContextProvider: FC<ContextProviderModalProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [itemName, setItemName] = useState("")
  const [items, setItems] = useState<Partial<ComponentSectionsForm> | null>(null)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <ModalContext.Provider
      value={{
        open: isOpen,
        context: items,
        setContext: setItems,
        closeModal: handleClose,
        openModal: handleOpen,
        itemName,
        setName: setItemName,
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}

const useApp: () => AppProps = () => useContext(Context)

const useModalContext: () => ModalProps = () => useContext(ModalContext)

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const router = createBrowserRouter(routes)

const App: FC = memo(() => (
  <ApolloProvider client={client}>
    <ContextProvider>
      <ModalContextProvider>
        <ConfigProvider theme={theme}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </ModalContextProvider>
    </ContextProvider>
  </ApolloProvider>
))

export { useApp, useModalContext }
export default App
