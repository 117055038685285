import { useMediaQuery } from "react-responsive"

const useBreakpoints = (): {
  isDesktop: boolean
  isPreDesktop: boolean
  isTablet: boolean
  isPreMobile: boolean
  isMobile: boolean
  isDesktopLarge: boolean
} => {
  return {
    isDesktopLarge: useMediaQuery({ maxWidth: 2560 }),
    isDesktop: useMediaQuery({ maxWidth: 1440 }),
    isPreDesktop: useMediaQuery({ maxWidth: 1024 }),
    isTablet: useMediaQuery({ maxWidth: 820 }),
    isPreMobile: useMediaQuery({ maxWidth: 425 }),
    isMobile: useMediaQuery({ maxWidth: 375 }),
  }
}

export { useBreakpoints }
